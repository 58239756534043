/* eslint-disable react/jsx-no-undef */
import React, {Component} from 'react';
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import {Divider, Form, Grid, Image, Segment} from "semantic-ui-react";
import logo from "../images/livingstone.png";
import Files from "react-files";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

class Livingstone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            materialArray: [],
            roomArray: [],
            totalCost: 0,
            choices: [],
            jsonFile: {}
        };

        this.fileReader = new FileReader();

        this.fileReader.onload = (event) => {

            // or do whatever manipulation you want on JSON.parse(event.target.result) here.


            this.setState({jsonFile: JSON.parse(event.target.result)}, () => {
                this.updateAllRooms(this.state.jsonFile.roomFinishingAreas);
                console.log("loaded", this.state.jsonFile.roomFinishingAreas);
            });
        };
    }

    componentDidMount() {
        this.refreshAll();
    }

    refreshAll() {
        let self = this;

        fetch(process.env.REACT_APP_API_LIVINGSTONE_URL + '/material/')
            .then(response => response.json())
            .then(function (data) {
                let tempArray = [];
                for (let item in data) {
                    const {cost, id, material} = data[item];
                    tempArray.push({
                        name: material,
                        value: id,
                        text: material + ' €' + cost + '/m2',
                        cost: cost
                    })
                }
                console.log(tempArray);
                self.setState({materialArray: tempArray});
            })
            .catch(error => console.log(error));

        fetch(process.env.REACT_APP_API_LIVINGSTONE_URL + '/room/')
            .then(response => response.json())
            .then(function (data) {
                self.setState({roomArray: data});
            })
            .catch(error => console.log(error));
    }

    calculateTotal() {
        let totalCost = 0;
        //TODO: fix this
        //eslint-disable-next-line
        Object.entries(this.state.choices).map((item) => {
            totalCost += this.state.roomArray.find(x => x.room === item[0]).area * this.state.materialArray.find(x => x.value === item[1]).cost;
        });
        console.log(totalCost);
        this.setState({totalCost: this.formatPrice(totalCost)});
    }

    createRevitJson() {
        let choiceArray = this.state.choices;

        let revitArray = {
            wallFinishing: {}
        };

        for (let choice in choiceArray) {
            revitArray.wallFinishing[choice] = this.state.materialArray.find(x => x.value === choiceArray[choice]).name;
        }

        console.log(revitArray);
        Livingstone.exportToJson(revitArray)
    }

    static exportToJson(objectData) {
        let filename = "revitExport-" + this.getFormattedTime() + ".json";
        let contentType = "application/json;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            let blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData, null, 2)))], {type: contentType});
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            let a = document.createElement('a');
            a.download = filename;
            a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData, null, 2));
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    static getFormattedTime() {
        var today = new Date();
        var y = today.getFullYear();
        // JavaScript months are 0-based.
        var m = today.getMonth() + 1;
        var d = today.getDate();
        var h = today.getHours();
        var mi = today.getMinutes();
        return y + "-" + m + "-" + d + "-" + h + "-" + mi;
    }

    handleChange = (e, {name, value}) => {
        this.setState({choices: Object.assign({}, this.state.choices, {[name]: value})}, () => {
            console.log(this.state.choices);
            this.calculateTotal()
        });
        console.log(this.state.choices)
    };

    updateAllRooms = (roomArray) => {
        let self = this;
        console.log(JSON.stringify({
            roomArray
        }));

        fetch(process.env.REACT_APP_API_LIVINGSTONE_URL + '/room/', {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
        }).then(function (response) {
            if (!response.ok) {
                console.log("error", response);
                throw new Error(response.statusText);
            }
            self.refreshAll();
            return response.json();
        }).catch(error => console.log(error.body));

        fetch(process.env.REACT_APP_API_LIVINGSTONE_URL + '/room/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(
                roomArray
            )
        }).then(function (response) {
            if (!response.ok) {
                console.log("error", response);
                throw new Error(response.statusText);
            }
            self.refreshAll();
            return response.json();
        }).then(
        ).catch(error => console.log(error.body));
    };

    formatPrice(price){
        return "€" + parseFloat(Math.round(price * 100) / 100).toFixed(2);
    }

    state = {};

    render() {
        return (
            <div className="white">
                <Grid verticalAlign='middle' stackable textAlign='center'>
                    <Grid.Row style={{height: "60vh"}}>
                        <Grid.Column style={{maxWidth: "800px", color: "white"}}>
                            <Segment inverted className="drop-shadow">
                                <Image size="small" src={logo} style={{margin: "auto"}}/>
                                <Divider/>
                                <Files
                                    className="files-dropzone white"
                                    onChange={file => {
                                        this.fileReader.readAsText(file[0]);
                                    }}
                                    onError={err => console.log(err)}
                                    accepts={[".json"]}
                                    multiple
                                    maxFiles={3}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    <Segment placeholder>
                                        <Header as='h4' icon>
                                            <Icon name='file outline'/>
                                            Import Revitdata
                                        </Header>
                                        <Button secondary className="livingstone">Add file</Button>
                                    </Segment>
                                </Files>
                                <Divider/>

                                <Form inverted>

                                    {this.state.roomArray.map((item, key) => {
                                        return (
                                            <Form.Group inline key={key}>
                                                <label style={{width: "20%"}}>{item.room} ({item.area} m2)</label>
                                                <Form.Select
                                                    // className={this.state.materialArray[configOption] !== "basis" ? "light-blue-gradient" : ""}
                                                    width={12} style={{width: "100%"}} fluid
                                                    options={this.state.materialArray}
                                                    name={item.room} key={key}
                                                    onChange={this.handleChange}
                                                />
                                                <label
                                                    style={{width: "20%"}}>{this.state.choices[item.room] && this.formatPrice(this.state.materialArray.find(x => x.value === this.state.choices[item.room]).cost * item.area)}</label>
                                            </Form.Group>
                                        )
                                    })}
                                </Form>
                                <Divider/>

                                <h2 style={{color: "#92C01F"}}>Total: {this.state.totalCost}</h2>

                                <Divider/>
                                <Button secondary className="livingstone" onClick={this.createRevitJson.bind(this)}>Export
                                    configuration to Revit</Button>
                            </Segment>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default Livingstone;
