import React, {Component} from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom';
import Redirect from "react-router/es/Redirect";
import home from "./components/Home";
import Dashboard from "./components/Dashboard";
import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
import ProjectSelector from "./components/Configurator/Choicegenerator/ProjectSelector";
import ChoiceGenerator from "./components/Configurator/Choicegenerator/ChoiceGenerator";
import Instructions from "./components/Configurator/Instructions";
import Login from "./components/Login";
import {Cookies} from "react-cookie";
import Livingstone from "./components/Livingstone";

function DashboardChild({match}) {
    return (
        <div>
            <Dashboard pageNumber={match.params.pageNumber}/>
        </div>
    );
}

const leftItems = [
    { as: "a", content: "Home", key: "home", to: "/" },
    { as: "a", content: "Dashboard", key: "dashboard", to: "/dashboard/1" }
];
const rightItems = [
    { as: "a", content: "Login", key: "login" },
];

function isAuthenticated() {
	const cookies = new Cookies();
	console.log(!!cookies.get("authToken"));
	return !!cookies.get("authToken");
}

function PrivateRoute({component: Component, ...rest}) {
	return (
		<Route
			{...rest}
			render={props =>
				isAuthenticated() ? (
					<Component {...props} />
				) : (
					<Component {...props} />
					// <Redirect
					// 	to={{
					// 		pathname: "/login",
					// 		state: {from: props.location}
					// 	}}
					// />
				)
			}
		/>
	);
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: 'dark',
        };
        this.toggleTheme = this.toggleTheme.bind(this);
    }

    toggleTheme() {
        let theme = this.state.theme === 'dark' ? 'light' : 'dark';
        console.log(theme);
        this.setState({ theme: theme });
        document.documentElement.setAttribute("data-theme", theme);
        console.log("triggered theme switch")
    }

  render() {
    return (
      <div className="App">
          <div className="page-content">
        {/*<header className="App-header">*/}
          {/*/!*<img src={logo} className="App-logo" alt="logo" />*!/*/}
        {/*</header>*/}
          <NavBar leftItems={leftItems} rightItems={rightItems}>
          </NavBar>

          <Switch>
              <PrivateRoute path="/" exact component={home}/>
              <Route path="/login" component={Login}/>
              <PrivateRoute path="/dashboard/:pageNumber" component={DashboardChild}/>
              <PrivateRoute path="/configurator/" exact component={ProjectSelector}/>
              <PrivateRoute path="/instructions/" component={Instructions}/>
              <PrivateRoute path="/choice-generator" exact component={ChoiceGenerator}/>
              <PrivateRoute path="/livingstone-demo" exact component={Livingstone}/>
          </Switch>

          </div>
          <Footer/>
          <button onClick={this.toggleTheme}/>
      </div>
    );
  }
}

export default App;
