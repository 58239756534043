/* eslint-disable react/jsx-no-undef */
import React, {Component} from 'react';
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import RequestChart from "./RequestChart";
import {Grid, Segment} from "semantic-ui-react";

class Home extends Component {
    constructor(props) {
        super(props);
        let self = this;

        fetch(process.env.REACT_APP_API_BASE_URL + '/maintenance')
            .then(response => response.json())
            .then(function (data) {
                self.setState({active: data.status});
            })
            .catch(error => console.log(error));
    }


    handleSwitch = () => {
        let self = this;
        fetch(process.env.REACT_APP_API_BASE_URL + '/maintenance', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({enabled: !self.state.active})
        }).then(function (response) {
            if (!response.ok) {
                console.log("error", response);
                throw new Error(response.statusText);
            }
        }).then(() => this.setState({active: !self.state.active}))
            .catch(error => console.log(error));
    };

    state = {};


    render() {
        const {active} = this.state;


        return (
            <Grid.Row verticalAlign='middle'>
                <Grid.Column>
                    <Segment style={{overflow: 'auto', height: "80vh", width: "90%", margin: "auto"}} inverted>
                        <RequestChart/>
                        <div>
                            {!active ? "Wrapper running" : "Wrapper in maintenance mode"}
                            <br/>
                            <br/>
                            <Button color={!active ? "green" : "grey"} onClick={this.handleSwitch} icon
                                    labelPosition='left'>
                                <Icon name={!active ? 'pause' : "play"}/>
                                {!active ? "Initiate Maintenance mode" : "Resume wrapper"}
                            </Button>
                        </div>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        );
    }
}

export default Home;
