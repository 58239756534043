import React, {Component} from "react";
import {Form, Header, Icon, Label, Segment} from "semantic-ui-react";

class BnrForm extends Component {
	render() {
		return (
			<Segment className="dashboard-section" inverted>
				<Header as='h2' icon textAlign='center'>
					<Icon name='warehouse' circular/>
					Kies bouwnummer
				</Header>
				<Form inverted>

					{Object.entries(this.props.bnrSpecificOptions).map((item, index) => {
						return <Label key={index} as='a' color='blue' image>
							{item[0]}
							<Label.Detail>{item[1]} </Label.Detail>
						</Label>
					})}

					<Form.Group inline style={{maxWidth: "50%", margin: "auto"}}>
						<label>Bouwnummer</label>
						<Form.Select width={12} style={{width: "100%"}} placeholder="Select a config type first" inline fluid options={this.props.bnrArray}
									 onChange={this.props.onBnrChange} name='bnr'
									 value={this.props.bnrValue}
						/>
					</Form.Group>


				</Form>
			</Segment>
		);
	}
}

export default BnrForm;