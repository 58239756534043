import _ from "lodash";
import React, {Component} from "react";
import {Container, Icon, Image, Menu, Responsive, Sidebar} from "semantic-ui-react";
import { Link } from 'react-router-dom'

const NavBarMobile = ({
						  children,
						  leftItems,
						  onPusherClick,
						  onToggle,
						  rightItems,
						  visible
					  }) => (
	<Sidebar.Pushable>
		<Sidebar
			as={Menu}
			animation="overlay"
			icon="labeled"
			inverted
			items={leftItems}
			vertical
			visible={visible}
		/>
		<Sidebar.Pusher
			dimmed={visible}
			onClick={onPusherClick}
			style={{ minHeight: "100vh" }}
		>
			<Menu fixed="top" inverted>
				<Menu.Item>
					<Image size="mini" src="https://asrr.nl/static/media/logo.4d8be6fc.svg" />
				</Menu.Item>
				<Menu.Item onClick={onToggle}>
					<Icon name="sidebar" />
				</Menu.Item>
				<Menu.Menu position="right">
					{_.map(rightItems, item => <Menu.Item {...item} />)}
				</Menu.Menu>
			</Menu>
			{children}
		</Sidebar.Pusher>
	</Sidebar.Pushable>
);

const NavBarDesktop = () => (
	<Menu fixed="top" inverted>
		<Menu.Item>
			<Image size="small" src="https://www.form.nl/images/form_logo-diap.svg" />
		</Menu.Item>
		<Menu.Item as={Link} to='/'>
			Home
		</Menu.Item>
		<Menu.Item as={Link} to='/dashboard/1'>
			Dashboard
		</Menu.Item>
		<Menu.Item as={Link} to='/choice-generator'>
			Choice Generator
		</Menu.Item>
		<Menu.Item as={Link} to='/instructions'>
			Instructions
		</Menu.Item>
		<Menu.Item as={Link} to='/livingstone-demo'>
			Livingstone Demo
		</Menu.Item>
		<Menu.Menu position="right">
			<Menu.Item as={Link} to='/login'>
				Login
			</Menu.Item>
		</Menu.Menu>
	</Menu>
);

const NavBarChildren = ({ children }) => (
	<Container style={{ marginTop: "5em" }}>{children}</Container>
);

class NavBar extends Component {
	state = {
		visible: false
	};

	handlePusher = () => {
		const { visible } = this.state;

		if (visible) this.setState({ visible: false });
	};

	handleToggle = () => this.setState({ visible: !this.state.visible });

	render() {
		const { children, leftItems, rightItems } = this.props;
		const { visible } = this.state;

		return (
			<div>
				<Responsive {...Responsive.onlyMobile}>
					<NavBarMobile
						leftItems={leftItems}
						onPusherClick={this.handlePusher}
						onToggle={this.handleToggle}
						rightItems={rightItems}
						visible={visible}
					>
						<NavBarChildren>{children}</NavBarChildren>
					</NavBarMobile>
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<NavBarDesktop leftItems={leftItems} rightItems={rightItems} />
					<NavBarChildren>{children}</NavBarChildren>
				</Responsive>
			</div>
		);
	}
}

export default NavBar;




