import {Cookies} from "react-cookie";

export const fetchConfigFile = (self) => {
	const cookies = new Cookies();

    fetch(process.env.REACT_APP_API_BASE_URL + `/dashboard/config-file/${self.state.projectName}/${self.state.configType}`,
        {
	        headers: {
		        'Content-Type': 'application/json',
		        'X-Auth-Token': cookies.get('authToken')
	        }
        })
        .then(response => response.json())
        .then(function (data) {
            let bnrArray = [];

            /**
             * @param data.settings.bnrConfigOptions BNR Specific Config Options
             */
            for (const item in data.settings.bnrConfigOptions) {
                bnrArray.push({key: item, text: item, value: item});
            }

            let configOptions = data.configOptions;
            let choiceObj = {};
            choiceObj = Object.assign({}, choiceObj, {bnr: bnrArray[0].key});

            for (const configOption in configOptions) {
                if (!configOption.startsWith('_')) {
                    choiceObj = Object.assign({}, choiceObj, {[configOption]: configOptions[configOption]["options"][0]})
                }
            }

            self.setState({
                configOptions: data.configOptions,
                settings: data.settings.bnrConfigOptions,
                bnrArray: bnrArray,
                choices: choiceObj,
                bnr: bnrArray[0].key
            });

            self.refreshBnr();
        }).catch(error => console.log(error));
};

