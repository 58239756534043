import React, {Component} from 'react';
import {Breadcrumb, Button, Checkbox, Divider, Dropdown, Form, Grid, Message, Segment} from 'semantic-ui-react';
import {Link} from "react-router-dom";
import BnrForm from "./BnrForm";

import {fetchConfigFile} from "./Functions/FetchConfigFile";
import {checkForLocal} from "./Functions/CheckForLocal";
import {submitConfiguration} from "./Functions/SubmitConfiguration";
import ProjectSelector from "./ProjectSelector";
import ConfigTypeSelector from "./ConfigTypeSelector";

class ChoiceGenerator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectName: 'KlokGroepWoenderskamp',
            configType: '',
            configOptions: [],
            settings: [],
            bnrArray: [],
            bnrSpecificOptions: {},
            bnr: '',
            choices: {},
            target: 'server',
            exportRevitFile: false,
            submitted: false,
            requestId: '',
            requestStatus: ''
        };

        checkForLocal(this);
    }

    componentDidMount() {
        let self = this;
        fetchConfigFile(self);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.configType !== this.state.configType) {
            console.log("update");
            fetchConfigFile(this);
        }
    }

    handleChange = (e, {name, value}) => {
        this.setState({choices: Object.assign({}, this.state.choices, {[name]: value})}, () => console.log(this.state.choices))
        console.log(this.state.choices)
    };

    handleChangeBnr = (e, {value}) => {
        this.setState({bnr: value, choices: Object.assign({}, this.state.choices, {bnr: value})}, () => this.refreshBnr());
        console.log(value)
    };


    refreshBnr() {
        let bnrSpecificOptions = {};

        // eslint-disable-next-line
        Object.entries(this.state.settings[this.state.bnr]).map((preset) => {
            bnrSpecificOptions[preset[0]] = preset[1];
        });

        this.setState({bnrSpecificOptions: bnrSpecificOptions});
    };

    getInstaller = () => {
        window.open(`/ClientInstaller.exe`, '_blank');
        window.focus();
    };

    handleLocationChange = (e, {value}) => {
        this.setState({target: value});
        console.log(value)
    };

    handleProjectChange = (e, {value}) => {
        this.setState({projectName: value});
        console.log(value)
    };

    handleTypeChange = (e, {value}) => {
        this.setState({configType: value});
        console.log(value)
    };

    handleRevitExportToggle = (e, {checked}) => {
        this.setState({exportRevitFile: checked});
    };

    convertChoicesToChangeList = () => {
        let configOptions = this.state.configOptions;
        let choices = this.updatedChoices();
        let changeList = {};
        changeList.changes = [];

        for (const choice in choices) {
            let configOption = configOptions[choice];
            let index = configOption.options.indexOf(choices[choice]);

            //List all the modelGroup changes of this configOption
            let modelGroupChanges = this.getModelGroupChanges(choices, choice, index, configOption.modelGroupElements);
            if (modelGroupChanges == null) //= configuration error
                return null;
            changeList.changes = changeList.changes.concat(modelGroupChanges);

            //List all the parameter changes of this configOption
            let parameterChanges = this.getParameterChanges(choices, choice, index, configOption.parameterElements);
            if (parameterChanges == null) //= configuration error
                return null;
            changeList.changes = changeList.changes.concat(parameterChanges);
        }

        console.log(changeList);
        return changeList;
    };

    //Retrieves the choices from current state as a full list (without bnr and with bnr specific options)
    updatedChoices = () => {
        let choices = this.state.choices;
        let bnrSpecificOptions = this.state.bnrSpecificOptions;
        let updatedChoices = Object.assign({}, bnrSpecificOptions);

        updatedChoices = Object.assign(updatedChoices, choices);
        delete updatedChoices.bnr;

        return updatedChoices;
    };

    /**
     *
     * @param choices
     * @param choice
     * @param index
     * @param modelGroupElements
     * @param modelGroupElements.switchCases
     * @param switchCase.modelGroupNames
     * @returns {*}
     */
    getModelGroupChanges = (choices, choice, index, modelGroupElements) => {
        let changes = [];

        for (const mgeName in modelGroupElements) {
            let modelGroupElement = modelGroupElements[mgeName];
            let elementId = modelGroupElement.elementId;

            let switchCase = this.getValidSwitchCase(choices, modelGroupElement.switchCases);
            if (switchCase == null) {
                let errorMessage = `Current configuration does not have a valid switchCase for ${mgeName}`;
                alert(errorMessage);
                console.log(errorMessage);
                return null;
            }

            let modelGroupName = switchCase.modelGroupNames[index];
            if (modelGroupName === "N/A") {
                let errorMessage = `Current configuration results in 'N/A' for ${mgeName}`;
                alert(errorMessage);
                console.log(errorMessage);
                return null;
            }

            let logMessage = `Switched modelgroup '${mgeName}' (id: ${elementId}) to '${modelGroupName}' because of: `;
            logMessage += this.writeRequirements(choice, choices[choice], switchCase);

            let modelGroupChange = {
                elementId,
                modelGroupName,
                logMessage
            };

            changes.push(modelGroupChange);
        }

        return changes;
    };

    getParameterChanges = (choices, choice, index, parameterElements) => {
        let changes = [];

        for (const peName in parameterElements) {
            let parameterElement = parameterElements[peName];
            let elementId = parameterElement.elementId;
            for (let parameterName in parameterElement.parameters) {
                let parameter = parameterElement.parameters[parameterName];

                let switchCase = this.getValidSwitchCase(choices, parameter.switchCases);
                if (switchCase == null) {
                    console.log(`Current configuration does not have a valid switchCase for ${parameterName} of ${peName}`);
                    return null;
                }

                let parameterValue = switchCase.values[index];
                let logMessage = `In parameter-element '${peName}' (id: ${elementId}): Changed parameter '${parameterName}' to '${parameterValue}' because of: `;
                logMessage += this.writeRequirements(choice, choices[choice], switchCase);

                let parameterChange = {
                    elementId: elementId,
                    parameterName: parameterName,
                    parameterValue: parameterValue,
                    logMessage: logMessage
                };

                changes.push(parameterChange);
            }
        }

        return changes;
    };

    getValidSwitchCase = (choices, switchCases) => {
        for (const index in switchCases) {
            let switchCase = switchCases[index];
            let isValid = true;
            let requirements = switchCase.requirements;
            if (requirements !== undefined && !this.isEmpty(requirements)) {
                for (const req in requirements) {
                    if (choices[req] !== requirements[req]) {
                        isValid = false;
                        break;
                    }
                }
            }

            if (isValid)
                return switchCase;
        }

        //No valid switchCase was found
        return null;
    };

    /**
     * @param choiceKey
     * @param choiceValue
     * @param switchCase
     * @returns {string}
     */
    writeRequirements = (choiceKey, choiceValue, switchCase) => {
        let result = this.writeRequirement(choiceKey, choiceValue);

        let requirements = switchCase.requirements;
        if (requirements == null)
            return result;

        for (let req in requirements)
            result += `, ${this.writeRequirement(req, requirements[req])}`;

        return result;
    };

    writeRequirement = (requirementKey, requirementValue) => {
        return `${requirementKey}='${requirementValue}'`;
    };

    getStatus = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/configuration/' + this.state.requestId + '/status', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        }).then(function (response) {
            if (!response.ok) {
                console.log("error", response);
                throw new Error(response.statusText);
            }
            return response.json();
        }).then(
            data => {
                console.log(data);
                this.setState({requestStatus: data})
            }
        ).catch(error => console.log(error));
    };

    isEmpty = (obj) => {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop))
                return false;
        }

        return true;
    };

    render() {
        const {configOptions, bnrArray} = this.state;
        const options = [
            {
                text: 'Local',
                value: 'local',
                icon: 'sitemap',
            },
            {
                text: 'Server',
                value: 'server',
                icon: 'server'
            },
        ];

        return (
            <div className="white">
                <Segment inverted>
                    <Breadcrumb size='big'>
                        <Breadcrumb.Section link as={Link} to={`/configurator/`}>Configurator</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron'/>
                        <Breadcrumb.Section link as={Link} to={`/configurator/${this.props.projectName}`}>Choice
                            Generator</Breadcrumb.Section>
                    </Breadcrumb>
                </Segment>


                <Grid columns={3} stackable textAlign='center'>
                    <Grid.Row>
                        <Grid.Column>
                            <ProjectSelector projectName={this.props.projectName} onChange={this.handleProjectChange}/>
                            <ConfigTypeSelector projectName={this.state.projectName} handleClick={this.handleTypeChange}
                                                currentConfigType={this.state.configType}/>
                            <BnrForm bnrSpecificOptions={this.state.bnrSpecificOptions} bnrArray={bnrArray}
                                     onBnrChange={this.handleChangeBnr} bnrValue={this.state.bnr}/>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment className="dashboard-section overflow"
                                     inverted>
                                <Form inverted>
                                    {Object.keys(configOptions).map((configOption, key) => {
                                        let options = [];
                                        if (configOption.startsWith('_')) {
                                            return null;
                                        }

                                        // eslint-disable-next-line
                                        configOptions[configOption]["options"].map((item) => {
                                            options.push({key: item, text: item, value: item});
                                        });

                                        return (

                                            <Form.Group inline>
                                                <label style={{width: "20%"}}>{configOption}</label>
                                                <Form.Select
                                                    className={this.state.choices[configOption] !== "basis" ? "light-blue-gradient" : ""}
                                                    width={12} style={{width: "100%"}} fluid options={options}
                                                    name={configOption} key={key}
                                                    defaultValue={options[0].key} onChange={this.handleChange}
                                                />
                                            </Form.Group>
                                        )
                                    })}
                                </Form>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment className="dashboard-section dark"
                                     inverted>
                                <Button onClick={() => this.getInstaller()}>Download configurator client</Button>
                                <Button onClick={() => checkForLocal(this)}>Detect local configurator client</Button>
                                <Button as={Link} to='/instructions'>See
                                    instructions</Button>
                                <Divider/>
                                <Dropdown label='Target' placeholder='Target' fluid selection options={options}
                                          onChange={this.handleLocationChange} value={this.state.target}/>

                                <Divider/>
                                <div style={{marginBottom: "1em"}}>
                                    {this.state.target === "server" ? <Checkbox label="Export Revit File" toggle
                                                                                onClick={this.handleRevitExportToggle}/> : null}
                                </div>

                                <Button positive onClick={() =>
                                    submitConfiguration(this)
                                }>Submit configuration</Button>

                                <Message hidden={!this.state.submitted}>Configuration submitted with
                                    requestId <b>{this.state.requestId}</b> <Button
                                        onClick={this.getStatus}>Status</Button></Message>

                                {this.state.requestStatus.status}
                                {this.state.requestStatus.queuePosition}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </div>
        );
    }
}

export default ChoiceGenerator;
