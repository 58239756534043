import React, {Component} from 'react';
import {Button, Icon, Modal, Table, Grid, Pagination} from "semantic-ui-react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Select from "semantic-ui-react/dist/commonjs/addons/Select";
import {CopyToClipboard} from "react-copy-to-clipboard";

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageNumber: this.props.pageNumber,
			requestArray: [],
			time: Date.now(),
			loading: true,
		};
	}

	componentDidMount() {
		this.setState({loading: true});

		this.fetchConfig();
		this.interval = setInterval(() => {
			this.fetchConfig();
			return this.setState({time: Date.now()})
		}, 5000);
		this.setState({loading: false});
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	fetchConfig() {
		let self = this;
		fetch(process.env.REACT_APP_API_BASE_URL + '/configuration/page/' + (this.props.pageNumber - 1))
			.then(response => response.json())
			.then(function (data) {
				self.setState({requestArray: data});
			})
			.catch(error => console.log(error));

	}

	handleRetry = (id, exportRevitFile, asNewRequest) => {
		fetch(process.env.REACT_APP_API_BASE_URL + '/configuration/retry', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({id: id, exportRevitFile: exportRevitFile, asNewRequest: asNewRequest})
		}).then(function (response) {
			if (!response.ok) {
				console.log("error", response);
				throw new Error(response.statusText);
			}
		}).catch(error => console.log(error));

		this.closeRetryModal();
		this.fetchConfig()
	};

	handleSkip = (id, skipMessage) => {
		fetch(process.env.REACT_APP_API_BASE_URL + '/configuration/skip', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({id: id, message: skipMessage})
		}).then(function (response) {
			if (!response.ok) {
				console.log("error", response);
				throw new Error(response.statusText);
			}
		}).catch(error => console.log(error));

		this.closeSkipModal();
		this.fetchConfig()
	};

	getExport = (id, fileType) => {
		window.open(process.env.REACT_APP_API_BASE_URL + `/configuration/${id}/file/${fileType}`, '_blank');
		window.focus();
	};

	state = {openRetryModal: false, id: '', exportRevitFile: '', asNewRequest: '', message: ''};

	showRetryModal = (id, exportRevitFile, asNewRequest, message) => () => this.setState({
		id: id,
		exportRevitFile: exportRevitFile,
		asNewRequest: asNewRequest,
		message: message,
		openRetryModal: true
	});
	closeRetryModal = () => this.setState({openRetryModal: false});

	showSkipModal = (id, skipMessage, message) => () => this.setState({
		id: id,
		skipMessage: skipMessage,
		message: message,
		openSkipModal: true
	});
	closeSkipModal = () => this.setState({openSkipModal: false});

	showRequestModal = (request) => () => {
		this.setState({
			request: request,
			showRequest: true
		});
	};
	closeRequestModal = () => this.setState({showRequest: false});

	handlePaginationChange = (e, {activePage}) => {
		window.location.href = '/dashboard/' + activePage;
		return this.setState({activePage})
	};

	render() {
		const {openRetryModal, openSkipModal, showRequest, pageNumber} = this.state;

		const projectNames = [
			{key: 'all', text: 'All', value: 'all'},
			{key: 'BebouwNexum', text: 'BebouwNexum', value: 'BebouwNexum'},
			{key: 'BoeleWestergouwe', text: 'BoeleWestergouwe', value: 'BoeleWestergouwe'},
			{key: 'KlokgroepWoendersKamp', text: 'KlokgroepWoendersKamp', value: 'KlokgroepWoendersKamp'},
		];

		return <div className="dashboard">
			<Segment inverted>
				<Button primary style={{margin: "0.5em"}} onClick={() => this.fetchConfig()}> REFRESH (Last
					refreshed {new Date(this.state.time).toISOString().slice(11, -1)}) </Button>

				<Grid.Column>
					<Pagination
						activePage={pageNumber}
						onPageChange={this.handlePaginationChange}
						totalPages={100}
						siblingRange={8}
					/>
				</Grid.Column>

				<Grid.Column>
					<Input style={{margin: "0.5em"}}
						   label={{basic: true, content: 'bnr'}}
						   labelPosition='right'
						   placeholder='Enter bnr...'
						   action>
						<input/>
						<Select compact options={projectNames} defaultValue='BebouwNexum'/>
					</Input>
				</Grid.Column>
			</Segment>

			<div>
				<Table celled selectable inverted>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell singleLine>ID</Table.HeaderCell>
							<Table.HeaderCell singleLine>BNR</Table.HeaderCell>
							<Table.HeaderCell>Project Name</Table.HeaderCell>
							<Table.HeaderCell>Duration</Table.HeaderCell>
							<Table.HeaderCell>Date Created</Table.HeaderCell>
							<Table.HeaderCell>Last Retried</Table.HeaderCell>
							<Table.HeaderCell>Request</Table.HeaderCell>
							<Table.HeaderCell>RID</Table.HeaderCell>
							<Table.HeaderCell>Exports</Table.HeaderCell>
							<Table.HeaderCell singleLine>Actions</Table.HeaderCell>
							<Table.HeaderCell>Exception</Table.HeaderCell>
							<Table.HeaderCell>Source</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{this.state.requestArray.map((item, index) => {

							// noinspection JSUnresolvedVariable
							return <Table.Row positive={item.duration !== 0 && item.duration !== -1}
											  negative={item.duration === -1} key={index}>

								<Table.Cell>
									{item.id}
								</Table.Cell>

								<Table.Cell>
									{item.bnr != null ? <b>{item.bnr.replace(/['"]+/g, '')}</b> : <b>none</b>}
								</Table.Cell>

								<Table.Cell>
									{item.projectName}
								</Table.Cell>

								{item.duration === -1 ?
									<Table.Cell>
										<Icon name='attention'/>
										{item.duration}
									</Table.Cell>
									:
									<Table.Cell>
										{item.duration}
									</Table.Cell>}

								<Table.Cell>
									{item.created}
								</Table.Cell>
								<Table.Cell>
									{item.retried}
								</Table.Cell>

								<Table.Cell>
									<Button.Group>
										<Button
											onClick={this.showRequestModal(JSON.stringify(JSON.parse(item.options),null,2))}><Icon name='magnify'/></Button>
										<CopyToClipboard text={item.options}
														 onCopy={() => this.setState({copied: true})}>
											<Button><Icon name='clipboard outline'/></Button>
										</CopyToClipboard>
									</Button.Group>
								</Table.Cell>

								<Table.Cell singleLine>
									{item.referenceId}
								</Table.Cell>

								<Table.Cell singleLine>
									<Button onClick={() => this.getExport(item.id, "pdf")}
											secondary={item.duration > 0}
											disabled={item.duration <= 0}>PDF</Button>
									<Button onClick={() => this.getExport(item.id, "rvt")}
											secondary={item.revitPath !== "" && item.revitPath !== null}
											disabled={item.revitPath === "" || item.revitPath === null}>RVT</Button>
									<Button disabled>DWG</Button>
									<Button onClick={() => this.getExport(item.id, "json")}
											secondary={item.duration > 0}
											disabled={item.duration <= 0}>JSON</Button>
									<Button onClick={() => this.getExport(item.id, "log")}
											secondary={item.duration > 0}
											disabled={item.duration <= 0}>Log</Button>
								</Table.Cell>

								<Table.Cell singleLine className="important-buttons">
									<Button className="warn"
											negative
											onClick={this.showRetryModal(item.id, true, true, "Are you sure you want to create a new export from this request?")}>
										Copy w/ export
									</Button>
									<Button className="warn"
											negative
											onClick={this.showSkipModal(item.id, "SkippedThroughDashboard", "Are you sure you want to skip this export?")}>
										Skip
									</Button>
									<Button negative
											onClick={this.showRetryModal(item.id, false, false, "Are you sure you want to retry this export without a revit file? This will update the files displayed on any dashboards linked to the database, which may result in a signed contract being updated")}>
										Retry
									</Button>
									<Button negative
											onClick={this.showRetryModal(item.id, true, false, "Are you sure you want to retry this export with a revit file? This will update the files displayed on any dashboards linked to the database, which may result in a signed contract being updated")}>
										Retry w/ export
									</Button>
								</Table.Cell>
								<Table.Cell>
									{item.exception}
								</Table.Cell>
								<Table.Cell>
									{item.source}
								</Table.Cell>

							</Table.Row>
						})}
					</Table.Body>
				</Table>

				<Grid.Column style={{paddingBottom: "2em"}}>
					<Pagination
						activePage={pageNumber}
						onPageChange={this.handlePaginationChange}
						totalPages={100}
					/>
				</Grid.Column>


				<Modal size={"mini"} open={openRetryModal} onClose={this.closeRetryModal}>
					<Modal.Content>
						<p>{this.state.message}</p>
					</Modal.Content>
					<Modal.Actions>
						<Button negative onClick={
							this.closeRetryModal}>No</Button>
						<Button positive icon='checkmark' labelPosition='right' content='Yes'
								onClick={() =>
									this.handleRetry(this.state.id, this.state.exportRevitFile, this.state.asNewRequest)
								}/>
					</Modal.Actions>
				</Modal>

				<Modal size={"mini"} open={openSkipModal} onClose={this.closeSkipModal}>
					<Modal.Content>
						<p>{this.state.message}</p>
					</Modal.Content>
					<Modal.Actions>
						<Button negative onClick={
							this.closeSkipModal}>No</Button>
						<Button positive icon='checkmark' labelPosition='right' content='Yes'
								onClick={() =>
									this.handleSkip(this.state.id, this.state.skipMessage)
								}/>
					</Modal.Actions>
				</Modal>

				<Modal size={"mini"} open={showRequest} onClose={this.closeRequestModal}>
					<Modal.Content>
						<pre>{this.state.request}</pre>
					</Modal.Content>
					<Modal.Actions>
						<Button negative onClick={
							this.closeRequestModal}>Close</Button>
					</Modal.Actions>
				</Modal>
			</div>
		</div>;
	}
}

export default Dashboard;