export const checkForLocal = (self) => {
    fetch(process.env.REACT_APP_CLIENT_URL, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            type: "ping",
            projectName: self.props.projectName,
            content: {
                message: "hoi"
            },
        })
    }).then(function (response) {
        if (!response.ok) {
            console.log("error", response);
            throw new Error(response.statusText);
        }
        return response.json();
    }).then(data => {
            self.setState({target: 'local'});
            console.log(data)
        }
    ).catch(error => console.log(error));
};

