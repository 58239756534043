import React, {Component} from 'react';
import Step from "semantic-ui-react/dist/commonjs/elements/Step/Step";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

const centerStyle = {
    maxWidth: "800px",
    margin: "auto",
    paddingBottom: "2em",
    paddingTop: "1em",
    backgroundColor: "white",
    textAlign: "left"
};

class Instructions extends Component {
    render() {
        return (
            <div>

                <div style={centerStyle}>
                    <h2 style={{fontSize: "24px", marginLeft: "0.5em"}}>How does it work?</h2>
                    <Step.Group fluid vertical>
                        <Step href="/login">
                            <Icon name='hand point up outline'/>
                            <Step.Content>
                                <Step.Title>Sign in </Step.Title>
                                <Step.Description>Use the credentials supplied by your administrator</Step.Description>
                            </Step.Content>
                        </Step>

                        <Step>
                            <Icon name='desktop'/>
                            <Step.Content>
                                <Step.Title>Close Revit</Step.Title>
                                <Step.Description>It's best to have Revit closed during the installation process</Step.Description>
                            </Step.Content>
                        </Step>

                        <Step href="/installation">
                            <Icon name='desktop'/>
                            <Step.Content>
                                <Step.Title>Download & Install the Revit plugin</Step.Title>
                                <Step.Description>Click this link to go to the installation page/guide</Step.Description>
                            </Step.Content>
                        </Step>

                        <Step>
                            <Icon name='desktop'/>
                            <Step.Content>
                                <Step.Title>Restart Revit and open the correct project</Step.Title>
                                <Step.Description>Open the project file that corresponds to the configurator </Step.Description>
                            </Step.Content>
                        </Step>

                        <Step>
                            <Icon name='chrome'/>
                            <Step.Content>
                                <Step.Title>Go to choice generator page</Step.Title>
                                <Step.Description>Open the choice generator in this web application</Step.Description>
                            </Step.Content>
                        </Step>

                        <Step>
                            <Icon name='chrome'/>
                            <Step.Content>
                                <Step.Title>Project</Step.Title>
                                <Step.Description>Select the proper project</Step.Description>
                            </Step.Content>
                        </Step>

                        <Step>
                            <Icon name='chrome'/>
                            <Step.Content>
                                <Step.Title>Config type</Step.Title>
                                <Step.Description>Select the house config type</Step.Description>
                            </Step.Content>
                        </Step>

                        <Step>
                            <Icon name='home' color={"black"}/>
                            <Step.Content>
                                <Step.Title>Select bnr</Step.Title>
                                <Step.Description>Select the "bouwnummer", the specific options for that bnr will be automatically filled in</Step.Description>
                            </Step.Content>
                        </Step>

                        <Step>
                            <Icon name='home' color={"black"}/>
                            <Step.Content>
                                <Step.Title>Configure your house</Step.Title>
                                <Step.Description> "bouwnummer" or houseId</Step.Description>
                            </Step.Content>
                        </Step>

                        <Step>
                            <Icon name='send'/>
                            <Step.Content>
                                <Step.Title>Click submit request to send the request to Revit</Step.Title>
                            </Step.Content>
                        </Step>

                        <Step>
                            <Icon name='checkmark' color='green'/>
                            <Step.Content>
                                <Step.Title>Confirm validity</Step.Title>
                                <Step.Description>Check if there were any Revit errors to see if there is anything that needs to be changed in the model</Step.Description>
                            </Step.Content>
                        </Step>

                    </Step.Group>
                </div>
            </div>
        );
    }
}

export default Instructions;