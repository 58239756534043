import React, {Component} from 'react';
import {Form, Segment} from "semantic-ui-react";

class ProjectSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requestArray: [],
            options: []
        }
    }

    componentDidMount() {
        let self = this;
        console.log(this);
        fetch(process.env.REACT_APP_API_BASE_URL + '/dashboard/projects/')
            .then(response => response.json())
            .then(function (data) {
                let projects = [];
                /**
                 * @param data.settings.bnrConfigOptions BNR Specific Config Options
                 */
                for (let index in data.projects) {
                    if(data.projects[index] !== "NoProject"){
                        projects.push({key: index, text: data.projects[index], value: data.projects[index]});
                    }
                }

                self.setState({options: projects, projectName: projects[0].text});
            })
            .catch(error => console.log(error));
    };

    render() {
        return (
            <Segment inverted className="dashboard-section" >
                 	<Form inverted>
                         <Form.Select fluid label="Project Name" options={this.state.options}
                                             onChange={this.props.onChange} name='bnr'
                                             value={this.props.projectName} />
                    </Form>
            </Segment>
        );
    }
}

export default ProjectSelector;
