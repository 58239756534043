import React, {Component} from 'react';
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import {Divider, Form, Grid, Image, Segment} from "semantic-ui-react";
import logo from '../logo.svg';
import Cookies from "react-cookie/es6/Cookies";
import Message from "semantic-ui-react/dist/commonjs/collections/Message/Message";
import jwt_decode from "jwt-decode";

const loginFormStyle = {
    margin: "auto",
    padding: "0 1em 2em 1em",
};

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formError: ""
        }

    }

    state = {password: '', email: '', submittedPassword: '', submittedEmail: ''};

    handleChange = (e, {name, value}) => this.setState({[name]: value});

    handleSubmit = () => {
        let self = this;
        const {email, password} = this.state;
        this.setState({submittedPassword: password, submittedEmail: email});
        fetch('https://hes.api.asrr-tech.com:9001/api/v1/auth/email/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        }).then(function (response) {
            if (!response.ok) {
                response.json().then(function (object) {
                    console.log(object);
                    console.log(object.propertyErrors);
                    self.setState({formError: object.propertyErrors})
                });
                throw new Error(response.statusText);
            }
            return response.json()
        }).then(function (data) {
            const cookies = new Cookies();
            cookies.set('authToken', data.token, {path: '/'});

            let decoded = jwt_decode(data.token);
            if (decoded.authorities.map(a => a.authority).includes('ROLE_MANAGEMENT')) {
                window.location.replace("/dashboard/1")
            } else {
                window.location.replace("/dashboard/1")
            }
        }).catch(error => console.log(error));
    };

    render() {
        const {password, email} = this.state;

        return (
            <div className="white">
                <Grid verticalAlign='middle' stackable textAlign='center'>
                    <Grid.Row style={{height: "60vh"}}>
                        <Grid.Column style={{maxWidth: "600px", color: "white"}}>
                            <Segment inverted className="drop-shadow">
                                <Image size="small" src={logo} style={{margin: "auto"}}/>
                                <h2 style={{color: "white"}}>Sign in to FORM Dashboard</h2>
                                <Divider/>
                                <Form style={loginFormStyle} onSubmit={this.handleSubmit} error={this.state.formError} inverted>
                                    <Form.Input name="email" value={email} label='Email' placeholder='test@form.nl'
                                                onChange={this.handleChange}/>
                                    <Form.Input name="password" value={password} label='Enter Password' type='password' placeholder='password'
                                                onChange={this.handleChange}/>
                                    <Button primary size="large" type='submit'>Sign In</Button>
                                    <Message error>
                                        There were some problems authenticating with the following errors:
                                        <Message.List items={this.state.formError}/>
                                    </Message>
                                </Form>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default Login;
