import React, {Component} from 'react';

class RequestChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartArray: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.setState({loading: true});

        this.fetchConfig();
        this.interval = setInterval(() => {
            this.fetchConfig();
            return this.setState({time: Date.now()})
        }, 5000);
        this.setState({loading: false});
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    fetchConfig() {
        let self = this;
        fetch(process.env.REACT_APP_API_BASE_URL + '/configuration/page/0')
            .then(response => response.json())
            .then(function (data) {
                self.setState({chartArray: data.reverse()});
            })
            .catch(error => console.log(error));
    }

    render() {
        return (
            <div className="chart">
                {/*<LineChart style={{margin: "auto"}}*/}
                           {/*width={500}*/}
                           {/*height={300}*/}
                           {/*data={this.state.chartArray}*/}
                           {/*margin={{*/}
                               {/*top: 5, right: 30, left: 20, bottom: 5,*/}
                           {/*}}*/}
                {/*>*/}
                    {/*<CartesianGrid strokeDasharray="3 3"/>*/}
                    {/*<XAxis dataKey="id"/>*/}
                    {/*<YAxis/>*/}
                    {/*<Tooltip/>*/}
                    {/*<Legend/>*/}
                    {/*<Line type="monotone" dataKey="duration" stroke="#8884d8" activeDot={{r: 8}}/>*/}
                {/*</LineChart>*/}
	            <iframe width="933" height="700" title="powerbi"
	src="https://app.powerbi.com/view?r=eyJrIjoiNzg2OTIyYmUtNGM5MC00ZjRhLWFiMDQtYzEzYTQxY2E4NDMxIiwidCI6ImViOGY4MDAxLTRkOTItNDU5ZC1hMTA1LTQ4Nzk4MzY5NmU4ZCIsImMiOjl9"
	frameBorder="0" allowFullScreen="true"/>
            </div>
        );
    }
}

export default RequestChart;