import React, {Component} from 'react';
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {Segment} from "semantic-ui-react";
import {Cookies} from "react-cookie";

class ConfigTypeSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectName: this.props.projectName,
            currentConfigType: this.props.configType,
            requestArray: [],
        };
    }

    componentDidMount() {
        this.refresh();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.projectName !== this.props.projectName){
            this.refresh()
        }
    }

    refresh() {
        let self = this;
        let cookies = new Cookies();
        fetch(process.env.REACT_APP_API_BASE_URL + `/dashboard/config-types/${this.props.projectName}`,
	        {
		        headers: {
			        'Content-Type': 'application/json',
			        'X-Auth-Token': cookies.get('authToken')
		        }
	        })
            .then(response => response.json())
            .then(function (data) {
                console.log(data);
                self.setState({requestArray: data.configTypes});

                let options = [];

                // eslint-disable-next-line
                Object.entries(data.configTypes).map((type) => {
                    options.push({text: type, value: type});
                });

                self.setState({options: options});

            })
            .catch(error => console.log(error));
    }


    render() {
        return (
            <Segment className="dashboard-section" inverted>
                <div className="button-array">
                    {this.state.requestArray.map((item,index) => {
                            item = item.replace(".json", "");

                            return <Button key={index} value={item} onClick={this.props.handleClick} size={"large"} style={{margin: "0.25em"}} positive={item === this.props.currentConfigType}>
                                {item}
                            </Button>
                        }
                    )}
                </div>
            </Segment>

        );
    }
}

export default ConfigTypeSelector;
