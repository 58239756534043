export const submitConfiguration = (self) => {
    if (self.state.target === 'server') {
        fetch(process.env.REACT_APP_API_BASE_URL + '/configuration/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                projectName: self.state.projectName,
                exportRevitFile: self.state.exportRevitFile,
                source: "FORM-Dashboard",
                configurationRequest: self.state.choices
            })
        }).then(function (response) {
            if (!response.ok) {
                console.log("error", response);
                throw new Error(response.statusText);
            }
            return response.json();
        }).then(
            data => {
                console.log(data);
                self.setState({submitted: true, requestId: data.configurationId})
            }
        ).catch(error => console.log(error));

    } else if (self.state.target === 'local') {
        let changeList = self.convertChoicesToChangeList();
        if (changeList == null) {
            console.log("Invalid Configuration. Did not submit");
            return;
        }
        fetch(process.env.REACT_APP_CLIENT_URL, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                type: "start_export",
                projectName: self.props.projectName,
                content: changeList,
            })
        }).then(function (response) {
            if (!response.ok) {
                console.log("error", response);
                throw new Error(response.statusText);
            }
            console.log("submitted")
        }).catch(error => console.log(error));
    }
};

